//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-368:_1616,_5012,_4012,_4500,_1932,_9088,_7996,_7488;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-368')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-368', "_1616,_5012,_4012,_4500,_1932,_9088,_7996,_7488");
        }
      }catch (ex) {
        console.error(ex);
      }